
// @c an alias to /compontents
import simpleHeader from '@c/simpleHeader.vue'
import simpleFooter from '@c/simpleFooter.vue'
export default {
    name: 'Login',
    components: {
        simpleHeader,
        simpleFooter
    },
    data() {
        return {
            passwordType: 1,
            forgetForm: {
                username: '',
                code: ''
            },
            setForm: {
                phone: '',
                password: ''
            }
        }
    }
}
